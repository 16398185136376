import { Textures } from './textures';
import * as THREE from 'three';
import { Scene } from 'three';

export const GOOD_FPS = 35;
export const TARGET_LOW_FPS = 25;
const dynamicQuality = true;
export let initialAntialiasing = true;

export class QualityDetector {
    private qdFrameCount = 0;
    private qdTime = 0;
    private decreaseCount = 0;
    antialiasing: boolean = initialAntialiasing;
    antialiasingCurrent: boolean = initialAntialiasing;
    anisotropy: number = 16;
    scene: THREE.Scene;
    setupAntialiasing: (a: boolean) => void;

    constructor(scene: Scene, setupAntialiasing: (a: boolean) => void) {
        this.scene = scene;
        this.setupAntialiasing = setupAntialiasing;
        this.applyAnisotropy();
        this.applyAntialiasing();
    }

    public update(deltaTime: number) {
        this.qdFrameCount++;
        this.qdTime += deltaTime;
        if (!dynamicQuality) {
            return;
        }
        const avgFps = this.qdFrameCount / this.qdTime;
        if (this.qdTime > 5) {
            if (avgFps < TARGET_LOW_FPS) {
                this.decrease(avgFps);
                this.qdTime = 0;
                this.qdFrameCount = 0;
                this.decreaseCount++;
            }
        }
        if (this.qdTime > 15) {
            if (avgFps > GOOD_FPS && this.decreaseCount === 0) {
                this.increase(avgFps);
                this.qdTime = 0;
                this.qdFrameCount = 0;
            }
        }
    }

    private decrease(avgFps: number) {
        if (this.antialiasing) {
            console.log('Quality detection: disable antialiasing; avg FPS is', avgFps);
            this.antialiasing = false;
            this.applyAntialiasing();
        } else if (this.anisotropy > 1) {
            this.anisotropy = Math.floor(this.anisotropy / 2);
            console.log(
                'Quality detection: decrease anisotropy to',
                this.anisotropy,
                '; avg FPS is',
                avgFps,
            );
            this.applyAnisotropy();
        }
    }

    applyAnisotropy() {
        console.log('Apply anisotropy', this.anisotropy);
        Textures.configureQuality(this.scene, this.anisotropy, false);
    }

    applyAntialiasing() {
        if (this.antialiasing !== this.antialiasingCurrent) {
            this.antialiasingCurrent = this.antialiasing;
            this.setupAntialiasing(this.antialiasing);
        }
    }

    private increase(avgFps: number) {
        if (this.anisotropy < this.getMaxAnisotropy()) {
            this.anisotropy = Math.min(this.getMaxAnisotropy(), Math.ceil(this.anisotropy * 2));
            console.log(
                'Quality detection: increase anisotropy to',
                this.anisotropy,
                '; avg FPS is',
                avgFps,
            );
            this.applyAnisotropy();
        }
    }

    getMs() {
        return this.qdTime / this.qdFrameCount;
    }

    getMaxAnisotropy(): number {
        return 16;
    }
}
