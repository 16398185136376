/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ConfigDto {
    MIN_SERVER_SPEED: number;
    MAX_SERVER_SPEED: number;
    TIME_SCALE: number;
    FIXED_INTERVAL_H: number;
    DECAY_INTERVAL_H: number;
    DECAY_RATE: number;
    REWARD_PER_HOUR: number;
    FARMING_SESSION_INTERVAL_H: number;
    TAP_REWARD: number;
}

export interface FarmSessionInfoDto {
    /** @example "5.34" */
    farmedCoins: number;
    /** @example "5.34" */
    lastFarmedCoins: number;
    /**
     * Farming speed [0.1-1.0]
     * @example "0.95"
     */
    currentSpeed: number;
    /**
     * Farming speed [0.1-1.0]
     * @example "0.95"
     */
    lastSpeed: number;
    /** @format date-time */
    lastInteractionTime: string;
    /**
     * Time that user can send interaction request
     * @format date-time
     */
    nextInteractionTime: string;
    /**
     * Time that user start farm
     * @format date-time
     */
    startTime: string;
    /**
     * End time of mining
     * @format date-time
     */
    endTime: string;
}

export interface RoachPriceDto {
    cost: number;
    totalRoaches: number;
}

export interface UserInfoDto {
    telegramId: string;
    isPremium: boolean;
    username?: string;
    avatarUrl?: string;
    /** @format date-time */
    createdAt: string;
    refCode: string;
    balance: number;
    totalClaimedSessions: number;
    newTasksCount: number;
    /** CREATED_BY_MANAGER - 0, INTERACT_WITH_BOT - 1, LAUNCH_GAME - 2, REGISTERED - 3, FARMING_START - 4, INTERACT_WITH_FARMING - 5, CLAIMED_FARM_SESSION - 6 */
    status: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    energyGenerationSpeed: number;
    farmSessionInfo?: FarmSessionInfoDto | null;
    nextRoachPriceInfo: RoachPriceDto;
}

export interface InitDataDto {
    token: string;
    /** @format date-time */
    serverTime: string;
    config: ConfigDto;
    user: UserInfoDto;
}

export interface SignInBodyDto {
    initData: string;
    refCode?: string;
    gaTag?: string;
}

export interface UpdatedFarmSessionInfoDto {
    /** @example "5.34" */
    farmedCoins?: number;
    /** @example "5.34" */
    lastFarmedCoins?: number;
    /**
     * Farming speed [0.1-1.0]
     * @example "0.95"
     */
    currentSpeed?: number;
    /**
     * Farming speed [0.1-1.0]
     * @example "0.95"
     */
    lastSpeed?: number;
    /** @format date-time */
    lastInteractionTime?: string;
    /**
     * Time that user can send interaction request
     * @format date-time
     */
    nextInteractionTime?: string;
    /**
     * Time that user start farm
     * @format date-time
     */
    startTime?: string;
    /**
     * End time of mining
     * @format date-time
     */
    endTime?: string;
}

export interface UpdatedUserInfoDto {
    telegramId?: string;
    isPremium?: boolean;
    username?: string;
    avatarUrl?: string;
    /** @format date-time */
    createdAt?: string;
    refCode?: string;
    balance?: number;
    totalClaimedSessions?: number;
    newTasksCount?: number;
    /** CREATED_BY_MANAGER - 0, INTERACT_WITH_BOT - 1, LAUNCH_GAME - 2, REGISTERED - 3, FARMING_START - 4, INTERACT_WITH_FARMING - 5, CLAIMED_FARM_SESSION - 6 */
    status?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    energyGenerationSpeed?: number;
    nextRoachPriceInfo?: RoachPriceDto;
    farmSessionInfo?: UpdatedFarmSessionInfoDto | null;
}

export interface ReferrerInfoDto {
    /** @example "0.15" */
    multiplierLevelOne: number;
    /** @example "0.05" */
    multiplierLevelTwo: number;
    /** @example "100" */
    availableRefClaim: number;
    /** @example "200" */
    totalEarningFromRef: number;
    /**
     * If not provided - user will claim first time
     * @format date-time
     */
    nextAvailableClaimDate?: string | null;
}

export interface ReferralItemDto {
    /**
     * Telegram username
     * @example "sodiqit"
     */
    username?: string | null;
    /** Telegram avatar url */
    avatarUrl?: string | null;
    /** Telegram first name */
    name: string;
    /** Friend balance */
    balance: number;
    /** Invited referrals by friend ref code */
    totalReferrals: number;
}

export interface ReferralListDto {
    page: number;
    pageSize: number;
    count: number;
    result: ReferralItemDto[];
}

export interface SubscribeTaskInfoDto {
    url: string;
}

export interface TaskItemDto {
    id: string;
    description: string;
    reward: number;
    rewardType: 'MTGN';
    status: 'auto' | 'can_open' | 'opened' | 'can_claim' | 'claimed';
    /** Provided if task is subscribe type */
    subscribeTaskInfo?: SubscribeTaskInfoDto;
}

export interface DailyRewardItemDto {
    id: string;
    description: string;
    reward: number;
    rewardType: 'MTGN';
    status: 'auto' | 'can_open' | 'opened' | 'can_claim' | 'claimed';
    /** Provided if task is subscribe type */
    subscribeTaskInfo?: SubscribeTaskInfoDto;
    day: number;
}

export interface DailyRewardInfoDto {
    streakDay: number;
    rewards: DailyRewardItemDto[];
}

export interface ErrorCheckValidationResultDto {
    taskId: string;
    error: 'telegramUserNotSubscribed';
}

export interface GetTasksResponseDto {
    tasks: TaskItemDto[];
    dailyReward: DailyRewardInfoDto;
    user: UpdatedUserInfoDto;
    errorCheckResult?: ErrorCheckValidationResultDto;
}

export interface StartTaskBodyDto {
    taskId: string;
}

export interface ClaimTaskResponseDto {
    tasks: TaskItemDto[];
    dailyReward: DailyRewardInfoDto;
    user: UpdatedUserInfoDto;
    errorCheckResult?: ErrorCheckValidationResultDto;
}

export interface FarmTapDto {
    speed: number;
}

export interface RoachModelUrls {
    veryLow: string;
    low: string;
    high: string;
}

export interface RoachTraitInfoDto {
    type: 'BODY' | 'HEAD' | 'MOUTH' | 'EYES' | 'ANTENNAE' | 'WINGS' | 'SHOES';
    asset: string;
    hue: number;
    saturation: number;
    lightness: number;
}

export interface RoachChargeInfoDto {
    chargedEnergy: number;
    /** @format date-time */
    completionTime: string;
    /** @format date-time */
    updateTime: string;
    /** @format date-time */
    startTime: string;
}

export interface RoachInfoDto {
    id: number;
    name: string;
    coin:
        | 'BTC'
        | 'ETH'
        | 'POL'
        | 'LINK'
        | 'XRP'
        | 'PERLIN'
        | 'LDO'
        | 'CRV'
        | 'SIN'
        | 'SOL'
        | 'DOGE'
        | 'WIF'
        | 'TON'
        | 'BNB';
    coinDirection: 'UP' | 'DOWN';
    maxEnergy: number;
    currentEnergy: number;
    energyGenerationSpeed: number;
    /** @format date-time */
    revealTime: string;
    avatarUrl: string;
    modelUrls: RoachModelUrls;
    status: 'available' | 'charging' | 'in_race';
    userId: string;
    traits: RoachTraitInfoDto[];
    chargeSession?: RoachChargeInfoDto;
}

export interface BuyRoachResponseDto {
    roach: RoachInfoDto;
    user: UpdatedUserInfoDto;
}

export interface BuyRoachBodyDto {
    name: string;
}

export interface UpdatedRoachChargeInfoDto {
    chargedEnergy?: number;
    /** @format date-time */
    completionTime?: string;
    /** @format date-time */
    updateTime?: string;
    /** @format date-time */
    startTime?: string;
}

export interface UpdatedRoachInfoDto {
    id?: number;
    name?: string;
    coin?:
        | 'BTC'
        | 'ETH'
        | 'POL'
        | 'LINK'
        | 'XRP'
        | 'PERLIN'
        | 'LDO'
        | 'CRV'
        | 'SIN'
        | 'SOL'
        | 'DOGE'
        | 'WIF'
        | 'TON'
        | 'BNB';
    coinDirection?: 'UP' | 'DOWN';
    maxEnergy?: number;
    currentEnergy?: number;
    energyGenerationSpeed?: number;
    /** @format date-time */
    revealTime?: string;
    avatarUrl?: string;
    modelUrls?: RoachModelUrls;
    status?: 'available' | 'charging' | 'in_race';
    userId?: string;
    traits?: RoachTraitInfoDto[];
    chargeSession?: UpdatedRoachChargeInfoDto;
}

export interface RoachUpgradeEnergyResponseDto {
    user: UpdatedUserInfoDto;
    roach: UpdatedRoachInfoDto;
}

export interface RoachUpgradeEnergyBodyDto {
    roachId: number;
    type: 'energy_speed_recovery' | 'tank_size';
}

export interface RoachEnergyUpgradeInfoDto {
    nextUpgradeCost: number;
    upgradesCount: number;
}

export interface RoachUpgradesInfoDto {
    energyRecoverySpeed: RoachEnergyUpgradeInfoDto;
    energyTankSize: RoachEnergyUpgradeInfoDto;
}

export interface RaceRegisterResponseDto {
    raceBackUrl: string;
    gameUuid: string;
    token: string;
}

export interface RaceRegisterDto {
    templateId: string;
    roachId: number;
}

export interface CurrentRaceDto {
    raceBackUrl: string;
    gameUuid: string;
    token: string;
    roachId: number;
}

export interface RaceTotalStatisticDto {
    energySpent: number;
    totalRounds: number;
}

export interface RaceRewardDto {
    rank: number;
    /** How many user place in race */
    count: number;
    reward: number;
}

export interface RaceResultDto {
    roachId: number;
    raceUuid: string;
    totalStatistic: RaceTotalStatisticDto;
    rewards: RaceRewardDto[];
}

export type RegisterRaceBackDto = object;

export type EmptyOk = object;

export interface ResetDto {
    userId: string;
}

export interface SetBalanceDto {
    userId: string;
    itemKind: 'MTGN';
    value: number;
}

export interface RemoveRoachDto {
    roachId: number;
}

export interface ResetStackedRoachInRaceDto {
    roachId: number;
}

export interface RemoveAllRoachesDto {
    username?: string;
    userId?: string;
}

export interface SetRoachEnergyDto {
    roachId: number;
    value: number;
}

import type {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    HeadersDefaults,
    ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
    extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request body */
    body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
    extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null,
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
    secure?: boolean;
    format?: ResponseType;
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance;
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private secure?: boolean;
    private format?: ResponseType;

    constructor({
        securityWorker,
        secure,
        format,
        ...axiosConfig
    }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected mergeRequestParams(
        params1: AxiosRequestConfig,
        params2?: AxiosRequestConfig,
    ): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method);

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method &&
                    this.instance.defaults.headers[
                        method.toLowerCase() as keyof HeadersDefaults
                    ]) ||
                    {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        if (input instanceof FormData) {
            return input;
        }
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent: any[] = property instanceof Array ? property : [property];

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }

            return formData;
        }, new FormData());
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<AxiosResponse<T>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;

        if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
            body = this.createFormData(body as Record<string, unknown>);
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body);
        }

        return this.instance.request({
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type ? { 'Content-Type': type } : {}),
            },
            params: query,
            responseType: responseFormat,
            data: body,
            url: path,
        });
    };
}

/**
 * @title RRC Game API
 * @version 1.0
 * @contact
 *
 * RRC Game API description
 */
export class TorApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    api = {
        /**
         * No description
         *
         * @name GetApiHealth
         * @request GET:/api/health
         */
        getApiHealth: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/health`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags auth
         * @name PostApiV1AuthSignIn
         * @request POST:/api/v1/auth/sign-in
         */
        postApiV1AuthSignIn: (data: SignInBodyDto, params: RequestParams = {}) =>
            this.request<
                InitDataDto,
                | {
                      error: 'badRequest';
                      message?: string | null;
                  }
                | {
                      error: 'unauthorized';
                      message?: string | null;
                  }
                | {
                      error: 'refCodeNotFoundButProvided';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/auth/sign-in`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user
         * @name GetApiV1UserMe
         * @request GET:/api/v1/user/me
         */
        getApiV1UserMe: (params: RequestParams = {}) =>
            this.request<
                UserInfoDto,
                | {
                      message?: 'Unauthorized';
                  }
                | {
                      error: 'userNotFound';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/user/me`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user
         * @name PostApiV1UserCreate
         * @request POST:/api/v1/user/create
         */
        postApiV1UserCreate: (params: RequestParams = {}) =>
            this.request<
                UpdatedUserInfoDto,
                | {
                      message?: 'Unauthorized';
                  }
                | {
                      error: 'userAlreadyCreatedProfile';
                      message?: string | null;
                  }
                | {
                      error: 'userNotFound';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/user/create`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags referrer
         * @name GetApiV1ReferrerMe
         * @request GET:/api/v1/referrer/me
         */
        getApiV1ReferrerMe: (params: RequestParams = {}) =>
            this.request<
                ReferrerInfoDto,
                | {
                      message?: 'Unauthorized';
                  }
                | {
                      error: 'userNotFound';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/referrer/me`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags referrer
         * @name GetApiV1ReferrerReferrals
         * @request GET:/api/v1/referrer/referrals
         */
        getApiV1ReferrerReferrals: (
            query?: {
                /** @default 1 */
                page?: number;
                /** @default 50 */
                pageSize?: number;
                /** @default "non sorted" */
                sortBalance?:
                    | 'ASC'
                    | 'ASC NULLS LAST'
                    | 'ASC NULLS FIRST'
                    | 'DESC'
                    | 'DESC NULLS LAST'
                    | 'DESC NULLS FIRST'
                    | 'asc'
                    | 'asc nulls last'
                    | 'asc nulls first'
                    | 'desc'
                    | 'desc nulls last'
                    | 'desc nulls first';
            },
            params: RequestParams = {},
        ) =>
            this.request<
                ReferralListDto,
                {
                    message?: 'Unauthorized';
                }
            >({
                path: `/api/v1/referrer/referrals`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags referrer
         * @name PostApiV1ReferrerClaim
         * @request POST:/api/v1/referrer/claim
         */
        postApiV1ReferrerClaim: (params: RequestParams = {}) =>
            this.request<
                UpdatedUserInfoDto,
                | {
                      message?: 'Unauthorized';
                  }
                | {
                      error: 'cannotClaimBeforeEndTime' | 'notAvailableRefClaim';
                      message?: string | null;
                  }
                | {
                      error: 'userNotFound';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/referrer/claim`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags tasks
         * @name GetApiV1Tasks
         * @request GET:/api/v1/tasks
         */
        getApiV1Tasks: (params: RequestParams = {}) =>
            this.request<
                GetTasksResponseDto,
                {
                    message?: 'Unauthorized';
                }
            >({
                path: `/api/v1/tasks`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags tasks
         * @name PostApiV1TasksStart
         * @request POST:/api/v1/tasks/start
         */
        postApiV1TasksStart: (data: StartTaskBodyDto, params: RequestParams = {}) =>
            this.request<
                GetTasksResponseDto,
                | {
                      error: 'Bad Request';
                      message?: string[];
                  }
                | {
                      message?: 'Unauthorized';
                  }
                | {
                      error: 'notFound';
                      message?: string | null;
                  }
                | {
                      error: 'taskCannotBeStarted' | 'taskAlreadyStarted';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/tasks/start`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags tasks
         * @name PostApiV1TasksCheck
         * @request POST:/api/v1/tasks/check
         */
        postApiV1TasksCheck: (data: StartTaskBodyDto, params: RequestParams = {}) =>
            this.request<
                GetTasksResponseDto,
                | {
                      error: 'Bad Request';
                      message?: string[];
                  }
                | {
                      message?: 'Unauthorized';
                  }
                | {
                      error: 'notFound';
                      message?: string | null;
                  }
                | {
                      error: 'taskCannotBeChecked';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/tasks/check`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags tasks
         * @name PostApiV1TasksClaim
         * @request POST:/api/v1/tasks/claim
         */
        postApiV1TasksClaim: (data: StartTaskBodyDto, params: RequestParams = {}) =>
            this.request<
                ClaimTaskResponseDto,
                | {
                      error: 'Bad Request';
                      message?: string[];
                  }
                | {
                      message?: 'Unauthorized';
                  }
                | {
                      error: 'notFound';
                      message?: string | null;
                  }
                | {
                      error: 'taskCannotBeClaimed';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/tasks/claim`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags farm
         * @name PostApiV1FarmStart
         * @request POST:/api/v1/farm/start
         */
        postApiV1FarmStart: (params: RequestParams = {}) =>
            this.request<
                UpdatedUserInfoDto,
                | {
                      message?: 'Unauthorized';
                  }
                | {
                      error: 'userAlreadyFarmTokens';
                      message?: string | null;
                  }
                | {
                      error: 'userNotFound';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/farm/start`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags farm
         * @name PostApiV1FarmTap
         * @request POST:/api/v1/farm/tap
         */
        postApiV1FarmTap: (data: FarmTapDto, params: RequestParams = {}) =>
            this.request<
                UpdatedUserInfoDto,
                | {
                      error: 'notFound';
                      message?: string | null;
                  }
                | {
                      error: 'cannotUpdateSessionAfterEndTime';
                      message?: string | null;
                  }
                | {
                      error: 'cannotUpdateSessionToFast';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/farm/tap`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags farm
         * @name PostApiV1FarmClaim
         * @request POST:/api/v1/farm/claim
         */
        postApiV1FarmClaim: (params: RequestParams = {}) =>
            this.request<
                UpdatedUserInfoDto,
                | {
                      error: 'notFound';
                      message?: string | null;
                  }
                | {
                      error: 'cannotClaimBeforeEndTime';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/farm/claim`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags roaches
         * @name GetApiV1Roaches
         * @request GET:/api/v1/roaches
         */
        getApiV1Roaches: (
            query?: {
                /** @default 1 */
                page?: number;
                /** @default 50 */
                pageSize?: number;
            },
            params: RequestParams = {},
        ) =>
            this.request<
                {
                    page?: number;
                    pageSize?: number;
                    count?: number;
                    result?: RoachInfoDto[];
                },
                {
                    message?: 'Unauthorized';
                }
            >({
                path: `/api/v1/roaches`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags roaches
         * @name PostApiV1RoachesBuy
         * @request POST:/api/v1/roaches/buy
         */
        postApiV1RoachesBuy: (data: BuyRoachBodyDto, params: RequestParams = {}) =>
            this.request<
                BuyRoachResponseDto,
                {
                    message?: 'Unauthorized';
                }
            >({
                path: `/api/v1/roaches/buy`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags roaches
         * @name PostApiV1RoachesRollRoachId
         * @request POST:/api/v1/roaches/roll/{roachId}
         */
        postApiV1RoachesRollRoachId: (roachId: string, params: RequestParams = {}) =>
            this.request<
                UpdatedRoachInfoDto,
                {
                    message?: 'Unauthorized';
                }
            >({
                path: `/api/v1/roaches/roll/${roachId}`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags roach
         * @name PostApiV1RoachUpgrade
         * @request POST:/api/v1/roach/upgrade
         */
        postApiV1RoachUpgrade: (data: RoachUpgradeEnergyBodyDto, params: RequestParams = {}) =>
            this.request<
                RoachUpgradeEnergyResponseDto,
                {
                    error: 'roachNotFound' | 'notEnoughFunds';
                    message?: string | null;
                }
            >({
                path: `/api/v1/roach/upgrade`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags roach
         * @name GetApiV1RoachUpgradeRoachId
         * @request GET:/api/v1/roach/upgrade/{roachId}
         */
        getApiV1RoachUpgradeRoachId: (roachId: string, params: RequestParams = {}) =>
            this.request<
                RoachUpgradesInfoDto,
                {
                    error: 'roachNotFound';
                    message?: string | null;
                }
            >({
                path: `/api/v1/roach/upgrade/${roachId}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags charge
         * @name PostApiV1ChargeStartRoachId
         * @request POST:/api/v1/charge/start/{roachId}
         */
        postApiV1ChargeStartRoachId: (roachId: string, params: RequestParams = {}) =>
            this.request<
                UpdatedRoachInfoDto,
                | {
                      error: 'roachCurrentCharging' | 'roachAlreadyCharged';
                      message?: string | null;
                  }
                | {
                      error: 'roachNotFound';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/charge/start/${roachId}`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags charge
         * @name PostApiV1ChargeStopRoachId
         * @request POST:/api/v1/charge/stop/{roachId}
         */
        postApiV1ChargeStopRoachId: (roachId: string, params: RequestParams = {}) =>
            this.request<
                UpdatedRoachInfoDto,
                | {
                      error: 'roachNotCharging';
                      message?: string | null;
                  }
                | {
                      error: 'roachNotFound';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/charge/stop/${roachId}`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags race
         * @name PostApiV1RaceRegister
         * @request POST:/api/v1/race/register
         */
        postApiV1RaceRegister: (data: RaceRegisterDto, params: RequestParams = {}) =>
            this.request<
                RaceRegisterResponseDto,
                | {
                      error: 'roachNotAvailableStatus' | 'roachAlreadyRegistered';
                      message?: string | null;
                  }
                | {
                      error: 'roachNotFound' | 'raceTemplateNotFound' | 'notEnoughEnergy';
                      message?: string | null;
                  }
            >({
                path: `/api/v1/race/register`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags race
         * @name GetApiV1RaceCurrent
         * @request GET:/api/v1/race/current
         */
        getApiV1RaceCurrent: (params: RequestParams = {}) =>
            this.request<
                CurrentRaceDto,
                {
                    error: 'notFound';
                    message?: string | null;
                }
            >({
                path: `/api/v1/race/current`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags race
         * @name PostApiV1RaceInternal
         * @request POST:/api/v1/race/internal
         */
        postApiV1RaceInternal: (params: RequestParams = {}) =>
            this.request<RaceResultDto, any>({
                path: `/api/v1/race/internal`,
                method: 'POST',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags balancer
         * @name PostApiV1BalancerRegister
         * @request POST:/api/v1/balancer/register
         */
        postApiV1BalancerRegister: (data: RegisterRaceBackDto, params: RequestParams = {}) =>
            this.request<EmptyOk, any>({
                path: `/api/v1/balancer/register`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags dev-tools
         * @name PostApiV1DevToolsTasksReset
         * @summary Reset all user tasks by provided user id
         * @request POST:/api/v1/dev-tools/tasks/reset
         */
        postApiV1DevToolsTasksReset: (data: ResetDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/v1/dev-tools/tasks/reset`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags dev-tools
         * @name PostApiV1DevToolsUserResetStatus
         * @summary Reset user status
         * @request POST:/api/v1/dev-tools/user/reset-status
         */
        postApiV1DevToolsUserResetStatus: (data: ResetDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/v1/dev-tools/user/reset-status`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags dev-tools
         * @name PostApiV1DevToolsUserBalance
         * @summary Set user balance to incoming value
         * @request POST:/api/v1/dev-tools/user/balance
         */
        postApiV1DevToolsUserBalance: (data: SetBalanceDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/v1/dev-tools/user/balance`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags dev-tools
         * @name PostApiV1DevToolsRoachRemove
         * @summary Remove provided roach
         * @request POST:/api/v1/dev-tools/roach/remove
         */
        postApiV1DevToolsRoachRemove: (data: RemoveRoachDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/v1/dev-tools/roach/remove`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags dev-tools
         * @name PostApiV1DevToolsRoachResetRace
         * @summary Reset stacked roach in race
         * @request POST:/api/v1/dev-tools/roach/reset-race
         */
        postApiV1DevToolsRoachResetRace: (
            data: ResetStackedRoachInRaceDto,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/api/v1/dev-tools/roach/reset-race`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags dev-tools
         * @name PostApiV1DevToolsUserRemoveAllRoaches
         * @summary Remove all user roaches
         * @request POST:/api/v1/dev-tools/user/remove-all-roaches
         */
        postApiV1DevToolsUserRemoveAllRoaches: (
            data: RemoveAllRoachesDto,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/api/v1/dev-tools/user/remove-all-roaches`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags dev-tools
         * @name PostApiV1DevToolsRoachSetEnergy
         * @summary Set current provided energy for roach
         * @request POST:/api/v1/dev-tools/roach/set-energy
         */
        postApiV1DevToolsRoachSetEnergy: (data: SetRoachEnergyDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/api/v1/dev-tools/roach/set-energy`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),
    };
}
