import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import './index.css';
import { useStore } from './store/globalStore';
import { doSignIn } from './helpers/api';
import { useHandleError } from './hooks/useHandleError';
import { Config } from './helpers/store';
import { UserStatus } from './api/tor-api';
import { Plug } from './pages/main/DevicePlug';
import { useSDKWrapper } from './helpers/sdkWrapper';

enum Screen {
    FIRST = 'FIRST',
    CREATE = 'CREATE',
    ACCOUNT = 'ACCOUNT',
}

const AppRoot = () => {
    console.log('App: render');
    const config = useStore((state) => state.config);
    const updateScreen = useStore((state) => state.updateScreen);
    const updateUser = useStore((state) => state.updateUser);
    const setConfig = useStore((state) => state.setConfig);
    const addToken = useStore((state) => state.addToken);
    const user = useStore((state) => state.user?.telegramId);

    const { appExpand, verticalSwipeDisabled } = useSDKWrapper();
    const navigate = useNavigate();
    const { handleError } = useHandleError();

    useEffect(() => {
        appExpand();
        verticalSwipeDisabled();

        // closingBehavior.enableConfirmation();

        //@ts-ignore
        window?.Telegram?.WebApp?.setHeaderColor('#000000');
        //@ts-ignore
        window?.Telegram?.WebApp?.setBackgroundColor('#000000');

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log('App.useEffect');
        doSignIn()
            .then((initData) => {
                if (initData) {
                    let newConfig: Config = {
                        ...config,
                        config: initData.config,
                        serverTimeDiffMs:
                            new Date(initData.serverTime).getTime() - new Date().getTime(),
                    };
                    setConfig(newConfig);
                    updateUser(initData.user);
                    addToken(initData.token);

                    if (
                        initData.user.status === UserStatus.REGISTERED ||
                        initData.user.status === UserStatus.INTERACT_WITH_FARMING ||
                        initData.user.status === UserStatus.FARMING_START ||
                        initData.user.status === UserStatus.CLAIMED_FARM_SESSION
                    ) {
                        updateScreen(Screen.ACCOUNT);
                        navigate('/home');
                    } else {
                        updateScreen(Screen.FIRST);
                        navigate('/start');
                    }
                }
            })
            .catch(handleError);

        // eslint-disable-next-line
    }, []);

    if (!user) {
        console.log('App: no user');
        return null;
    }
    return (
        <div className="flex max-w-[480px] mx-auto w-full text-white bg-black h-screen flex-col">
            {<Outlet />}
            <Plug />
        </div>
    );
};

export { AppRoot };
