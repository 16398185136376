import { Texture } from 'three/src/textures/Texture';
import { LinearFilter, LinearMipmapLinearFilter } from 'three/src/constants';
import { Object3D } from 'three/src/core/Object3D';
import { Mesh, MeshStandardMaterial } from 'three/src/Three';

export class Textures {
    static configureQuality(obj: Object3D, anisotropy: number, wireframe: boolean) {
        obj.traverse((obj) => {
            if (obj.type === 'Mesh') {
                const obj1 = obj as Mesh;
                const mat = obj1.material;
                const mat1 = mat as MeshStandardMaterial;
                if (mat1.isMeshStandardMaterial) {
                    mat1.depthWrite = true;
                    mat1.depthTest = true;
                    mat1.wireframe = wireframe;

                    this.setupTexture(anisotropy, mat1.map);
                    this.setupTexture(anisotropy, mat1.emissiveMap);
                    this.setupTexture(anisotropy, mat1.normalMap);
                    this.setupTexture(anisotropy, mat1.envMap);
                    this.setupTexture(anisotropy, mat1.alphaMap);
                    this.setupTexture(anisotropy, mat1.metalnessMap);
                    this.setupTexture(anisotropy, mat1.bumpMap);
                    this.setupTexture(anisotropy, mat1.roughnessMap);
                }
            }
        });
    }

    static setupTexture(anisotropy: number, map: Texture | null) {
        if (map != null) {
            map.minFilter = LinearMipmapLinearFilter;
            map.magFilter = LinearFilter;
            if (map.anisotropy !== anisotropy) {
                map.anisotropy = anisotropy;
                map.needsUpdate = true;
            }
        }
    }
}
