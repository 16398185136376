import { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import styles from './Button.module.scss';
import { useStore } from '../../../store/globalStore';
import {
    useBuyRoach,
    useChargeStart,
    useChargeStop,
    useRaceRegister,
} from '../../../pages/Home/api/roachQuery';
import { useRoachStore } from '../../../store/roachSlice';
import { useRaceStore } from '../../../store/raceSlice';
import { RoachInfoDto } from '../../../api/tor-api';
import { Countdown } from '../../../pages/main/AccountScreen/Home/Countdown';

enum HomeButtonState {
    Unlock = 'UNLOCK',
    Race = 'RACE',
    Charge = 'CHARGE',
    Charging = 'CHARGING',
    inRace = 'INRACE',
}

interface Props {
    roaches: RoachInfoDto[] | undefined;
}

const HomeButton: FC<Props> = ({ roaches }): ReactElement => {
    const [isChargingFinished, setIsChargingFinished] = useState<boolean>(false);
    const user = useStore((state) => state.user);
    const currentRoach = useRoachStore((state) => state.currentRoach);
    const inRace = useRaceStore((state) => state.inRace);
    const config = useStore((state) => state.config);

    const { mutate: buyRoach, isSuccess: isBuySuccess } = useBuyRoach();
    const { mutate: raceRegister, isPending: isRegisterPending } = useRaceRegister();
    const { mutate: startCharge, data: startChargeData } = useChargeStart();
    const { mutate: stopCharge } = useChargeStop();
    const navigate = useNavigate();

    const cost = user?.nextRoachPriceInfo.cost || 0;
    const roachId = currentRoach?.id || null;
    const endTime =
        currentRoach?.chargeSession?.completionTime ||
        startChargeData?.data.chargeSession?.completionTime;

    function getHomeButtonType(
        inRace: boolean,
        currentRoach: RoachInfoDto | null,
        roaches: RoachInfoDto[] | undefined,
    ): HomeButtonState {
        if (inRace) {
            return HomeButtonState.inRace;
        } else if (currentRoach && currentRoach.currentEnergy < 50) {
            if (currentRoach && currentRoach.status === 'charging') {
                return HomeButtonState.Charging;
            }
            return HomeButtonState.Charge;
        } else if ((roaches && roaches.length > 0 && currentRoach) || isBuySuccess) {
            return HomeButtonState.Race;
        } else return HomeButtonState.Unlock;
    }

    const type = getHomeButtonType(inRace, currentRoach, roaches);

    const isAvailable =
        user?.balance !== undefined &&
        user?.nextRoachPriceInfo.cost >= 0 &&
        user?.balance >= user?.nextRoachPriceInfo.cost;

    const handleUnlockClick = () => buyRoach({ name: user?.username || 'player' });
    const handleRaceClick = () =>
        roachId ? raceRegister({ roachId, templateId: 'trefoil' }) : null;

    useEffect(() => {
        if (isChargingFinished && currentRoach) {
            stopCharge(currentRoach?.id.toString());
        }
        // eslint-disable-next-line
    }, [isChargingFinished]);

    switch (type) {
        case HomeButtonState.Unlock:
            return (
                <button
                    className={clsx(styles.button, !isAvailable && styles.disabled)}
                    onClick={handleUnlockClick}
                    disabled={!isAvailable}
                >
                    {cost === 0 ? 'Get Roach' : `Unlock: ${cost} MGTN`}
                </button>
            );
        case HomeButtonState.Charge:
            return (
                <button
                    className={styles.button}
                    onClick={() => (currentRoach ? startCharge(currentRoach?.id.toString()) : null)}
                >
                    Charge
                </button>
            );

        case HomeButtonState.Charging:
            return (
                <>
                    <button className={clsx(styles.button, styles.progress)} disabled>
                        {isChargingFinished ? 'Charged' : 'Charging '}
                        {endTime && !isChargingFinished && (
                            <Countdown
                                endTime={endTime}
                                serverTimeDiffMs={config.serverTimeDiffMs}
                                className={styles.countdown}
                                onFinished={() => {
                                    setIsChargingFinished(true);
                                }}
                            />
                        )}
                    </button>
                </>
            );
        case HomeButtonState.Race:
            return (
                <button
                    className={clsx(styles.button, isRegisterPending && styles.disabled)}
                    onClick={handleRaceClick}
                    disabled={isRegisterPending}
                >
                    {!isRegisterPending ? 'Go to Race' : 'Loading Race'}
                </button>
            );
        case HomeButtonState.inRace:
            return (
                <button className={clsx(styles.button)} onClick={() => navigate('/race')}>
                    Back to race
                </button>
            );
        default:
            return (
                <button className={styles.button} onClick={handleRaceClick}>
                    Go to Race
                </button>
            );
    }
};

export default HomeButton;
