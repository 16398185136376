import { useEffect, useRef, useState } from 'react';
import { ChargingStats } from '../../../modules/ChargingStats';
import { HomeButton } from '../../../modules/HomeButton';
import { Status } from '../../../modules/StatusBar';
import styles from './Home.module.scss';
import { useStore } from '../../../store/globalStore';
import { RoachConstructorView } from '../../../components/RoachConstuctor';
import { LoadingManager } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useRoaches, useRollRoach } from '../api/roachQuery';
import { useRoachStore } from '../../../store/roachSlice';
import { Loader } from '../../../components/Loader/Loader';
import { RoachName } from '../../../components/RoachName/RoachName';
import { useRaceStore } from '../../../store/raceSlice';
import { RaceResults } from '../../../modules/RaceResults';
import { RollRoachButton } from '../../../components/RollRoach';

const Home = () => {
    const [isLoadingModel, setIsLoadingModel] = useState(false);

    const viewRef = useRef<RoachConstructorView | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const setIsShowIntro = useStore((state) => state.setIsShowIntro);
    const checkCurrentRace = useRaceStore((state) => state.checkCurrentRace);
    const isShowResult = useRaceStore((state) => state.isShowResult);
    const { currentRoach, setCurrentRoach } = useRoachStore();
    const { data } = useRoaches();
    const { mutate: reRollRoach } = useRollRoach();

    const roachModel = currentRoach?.modelUrls.high;
    const roaches = data?.pages[0].data.result;

    console.log('roach id', currentRoach?.id);

    const handleLoadModel = async (roachModel: string) => {
        const loadingManager = new LoadingManager();
        const loader = new GLTFLoader(loadingManager);

        loadingManager.onStart = () => {
            setIsLoadingModel(true);
        };

        try {
            const loadedRoachData = await loader.loadAsync(roachModel);

            // if (prevGroup.current) viewRef.current?.remove(prevGroup.current);
            //
            // isNeedLoad.current = false;
            // prevGroup.current = loadedRoachData.scene;

            viewRef.current?.setRoach(loadedRoachData.scene);
        } catch (e) {
            // add notify
        }

        setIsLoadingModel(false);
    };

    useEffect(() => {
        checkCurrentRace();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setIsShowIntro(false);
    }, [setIsShowIntro]);

    useEffect(() => {
        if (containerRef.current && roachModel) {
            viewRef.current = new RoachConstructorView(
                containerRef.current,
                styles['model-wrapper'],
                false,
            );

            handleLoadModel(roachModel);

            return () => {
                viewRef.current?.destroy();
            };
        }

        return () => {
            viewRef.current?.destroy();
        };
    }, [roachModel]);

    useEffect(() => {
        if (data?.pages[0].data.result && data?.pages[0].data.result.length > 0) {
            const newRoach = data.pages[0].data.result[0];
            setCurrentRoach(newRoach);
        }
    }, [data, setCurrentRoach]);

    return (
        <main className={styles.container}>
            <Status />

            <div id="containerRef" className={styles.roachContainer} ref={containerRef}></div>

            <div className={styles.bottom}>
                {currentRoach && (
                    <>
                        <RoachName
                            className={styles.roachName}
                            name={currentRoach.name}
                            coin={currentRoach.coin}
                        />
                        <div className={styles.rowContainer}>
                            <ChargingStats currentEnergy={currentRoach?.currentEnergy} />
                            <RollRoachButton
                                onClick={() => reRollRoach(currentRoach.id.toString())}
                            />
                        </div>
                    </>
                )}

                <div className={styles.buttonContainer}>
                    <HomeButton roaches={roaches} />
                </div>
            </div>
            {isLoadingModel && (
                <div className={styles.loader}>
                    <Loader />
                </div>
            )}
            {isShowResult && <RaceResults />}
        </main>
    );
};

export default Home;
